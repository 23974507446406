import React from 'react';
import {
  Paper,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardActionArea,
  Box
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Markdown from './components/Markdown';

import {
  useHistory,
  useRouteMatch,
  Route,
  Redirect,
} from 'react-router-dom';

import slugify from 'slugify';

import DalabGrid from './components/DalabGrid';

import { openInNewTab } from './utils';

export const current_people = [
  {
    name: 'Thomas Hofmann',
    role: 'Professor',
    image: 'images/people/ThomasHofmann/photo.jpg',
    email: 'hc.zhte.fni@nnamfoh.samoht',
    room: 'CAB F 48.1',
    address: 'Universitätstrasse 6, 8092 Zürich, Switzerland',
    markdown: `
## Office Hours

To request office hours on Tuesdays (11-12), please contact my assistant Paulina Motyka via [paulina.motyka@inf.ethz.ch](mailto:paulina.motyka@inf.ethz.ch)

##  Research Interests

How can we design intelligent machines? What are the mathematical and computational foundations of intelligence? How can we enable machines to understand language? What are the societal implications that come with progress in this area? These are questions that shape my research agenda.

We do not know how to directly program machine intelligence. Rather, we usually rely on learning from experience and data. My main interest thus is in machine learning, specifically in problems that involve learning algorithms as well as statistical models and their architectures, such as deep neural networks. My research topics include implicit (generative) models, non-convex optimization, and design patterns for deep architectures. I also have a strong interest in natural language understanding and machine reading of text. This includes topics like word and sentence embeddings, referential semantics, topic models, summarization, and conversational agents. In addition, I pursue a wide spectrum of applications from cosmology to medicine to marketing.

Finally, I care about turning academic research into innovation (see [1plusX](http://www.1plusx.com) and [Recommind](http://www.recommind.com)), but I also reflect on the implications of machine intelligence and automated decision making. Based on my education, I have a high appreciation for philosophical thinking. My teachers were [Josef Simon](http://en.wikipedia.org/wiki/Josef_Simon) and [Jacques Derrida](http://en.wikipedia.org/wiki/Jacques_Derrida).

##  Curriculum Vitae

1997 Ph.D. Computer Science, University of Bonn 1997-1999 Postdoctoral Fellow, MIT and UC Berkeley 1999-2004 Assistant/Associate Professor for Computer Science, Brown University 2001-2015 Co-founder, Chief Scientist, Recommind.com (now OpenText) 2004-2006 Professor for Computer Science, Technical University of Darmstadt 2004-2005 Director, Fraunhofer IPSI 2006-2013 Director of Engineering and co-site Lead, Google Zurich 2014-today Professor for Data Analytics, Department of Computer Science, ETH Zürich 2014-today Co-founder and CTO, [1plusX](http://www.1plusx.com) 2015-today Co-director [Max Planck-ETH Center for Learning Systems](http://learning-systems.org/)`
  },
  {
    name: 'Paulina Motyka',
    role: 'Administrative Assistant',
    image: 'images/people/PaulinaMotyka/image.jpg',
  },
  {
    name: 'Bobby He',
    role: 'Postdoc',
    image: 'images/people/BobbyHe/photo.jpg',
    markdown: `
I work at the intersection of theory and practice in deep learning: most of my work tries to improve our fundamental understanding of deep learning phenomena and training dynamics, and use these insights to improve the behaviour of neural networks in practice.

Previously, I completed my PhD in the Department of Statistics at Oxford, and obtained my Bachelor's and Master's degrees in Mathematics from Cambridge. During my PhD I interned at DeepMind and Samsung Research.`
  },
  {
    name: 'Janis Fluri',
    role: 'PostDoc',
    image: 'images/people/JanisFluri/photo.jpg',
    markdown: `[ ETH page ] (https://ethz.ch/staffnet/de/organisation/abteilungen/informatikdienste/personen/person-detail.MTg3Mjgy.TGlzdC8zODgsLTg1MzU3NzMxNA==.html)`
  },
  {
    name: 'Sotiris Anagnostidis',
    role: 'PhD Student',
    image: 'images/people/SotirisAnagnostidis/photo.jpg',
    markdown: `
I am a first year PhD student in the Data Analytics Lab. I obtained my Master's degree in Data Science from ETH and a Bachelor's degree in Electrical Engineering and Computer Science from NTUA. My research focuses on representation learning and shape encodings in neural networks.
      `
  },
  {
    name: 'Gregor Bachmann',
    role: 'PhD Student',
    image: 'images/people/GregorBachmann/photo.jpg',
    markdown: `
I’m a second year PhD student in Thomas Hofmann’s group. I’m interested in deep learning and its connection to other mathematical areas such as learning theory and statistical physics. Prior to joining the Data Analytics group, I obtained my bachelor and master degree in mathematics at ETH Zurich.
    `
  },
  {
    name: "Yuhui Ding",
    role: "PhD Student",
    image: "images/people/YuhuiDing/photo.jpg",
    markdown: `
I am a PhD student advised by Prof. Thomas Hofmann. I have broad interests in representation learning and optimization for neural networks.
For more information, please check my personal website: https://skeletondyh.github.io/
    `
  },
  {
    name: 'Dario Miro Konopatzki',
    role: 'PhD Student',
    image: 'images/people/placeholder.jpg',
    markdown: `I am primarily interested in world models. Before starting my PhD, I obtained a B.Sc. in Mathematics and an M.Sc. in Computer Science from the University of Munich, with a master's thesis on knowledge graph completion. I also spent time at Siemens, working on cognitively inspired scene graph classification.`
  },
  {
    name: 'Nodens Koren',
    role: 'PhD Student',
    image: 'images/people/NodensKoren/photo.jpg',
    markdown: `I am a PhD student in the GW-Learn project at ETH Zürich, working in the Data Analytics Lab under the supervision of Prof. Thomas Hofmann. Prior to joining the Data Analytics Lab, I obtained my Bachelor's degree in computer engineering from the University of Illinois at Urbana-Champaign, followed by my Master's degree in computer science from the University of Melbourne.

My research primarily revolves around enhancing the robustness of deep learning models and their application in AI4Science. For a comprehensive list of my publications, please refer to [Google Scholar](https://scholar.google.com/citations?user=4JdmvGoAAAAJ).`
  },
  {
    name: 'Giulia Lanzillotta',
    role: 'PhD Student, AI Center PhD Fellow',
    image: 'images/people/LanzillottaGiulia/photo.jpg',
    markdown: ``
  },
  {
    name: 'Lorenzo Noci',
    role: 'PhD Student',
    image: 'images/people/LorenzoNoci/photo.jpg',
    markdown: `I am a third year PhD student in the Data Analytics Lab. I am interested in various aspects of deep learning theory, from initialization to training dynamics and the effect of different architectural components (e.g. residual connections, attention mechanism, etc..). For a list of recent publications refer to [Google Scholar](https://scholar.google.com/citations?user=VZ__1r0AAAAJ&hl=en). 
    I obtained a Master's degree in Data Science from ETH, and a Bachelor's degree from Politecnico di Milano. I also lived in Luxembourg for six months and Shanghai for two years :).`
  },

  {
    name: "Enis Simsar",
    role: "PhD Student",
    image: "images/people/EnisSimsar/photo.jpg",
    markdown: `I am a Ph.D. student in the Data Analytics Lab. I received my Informatics Master's degree from Technical University of Munich and B.Sc. in Computer Engineering from Bogazici University, Istanbul.

I have a keen interest in Computer Vision and Natural Language Processing, with a particular focus on image generation and editing using generative adversarial networks, diffusion models and neural radiance fields. If you're interested, you can take a look at my personal website: https://enis.dev and [Google Scholar](https://scholar.google.com/citations?user=xDvFUb4AAAAJ&hl=en).`
  },
  {
    name: 'Sidak Pal Singh',
    role: 'PhD Student, CLS PhD Fellow',
    image: 'images/people/SidakPalSingh/photo.jpg',
    markdown: `
I am a PhD student at the Max Planck ETH Center for Learning Systems, advised by Thomas Hofmann (ETH Zürich) and  Bernhard Schölkopf (MPI-IS Tübingen). My research interests are in the broad areas of deep learning theory, optimization, and causal representation learning.

Previously, I finished my Master's degree in Data Science at EPFL, where I worked on [model fusion](https://arxiv.org/abs/1910.05653) and [natural language processing](http://proceedings.mlr.press/v108/singh20a.html) via optimal transport, advised by Martin Jaggi. During my master thesis, I worked with Dan Alistarh at IST Austria on [efficient second-order methods](https://arxiv.org/abs/2004.14340) for compressing neural networks. I received my Bachelor's degree in Computer Science at Indian Institute of Technology (IIT) Roorkee. 

For more information, check out my personal webpage: http://sidakpal.com/. And for publications, see [Google Scholar](https://scholar.google.com/citations?user=c59mPS4AAAAJ&hl=en).      `
  },
  {
    name: 'Piera Riccio',
    role: 'Associate Member, ELLIS PhD Student',
    image: 'images/people/PieraRiccio/photo.jpg',
    markdown: `
I am a PhD student at ELLIS Alicante (Spain), advised by Nuria Oliver and Thomas Hofmann (ETH Zurich). I am conducting interdisciplinary research in Art and Artificial Intelligence, particularly considering the cultural and ethical implications of self-expression online.

I obtained my Master's degree in Data Science & Engineering at Télécom Paris (EURECOM) and in ICT for Smart Societies at Politecnico di Torino. During my master thesis, I worked as a Research Assistant at the Oslo Metropolitan University.
I received my Bachelor's degree in Cinema and Media Engineering at Politecnico di Torino. In 2020, I was a Research Affiliate at Metalab (at) Harvard University.`
  },
];

export const alumni = [
  {
    name: 'Antonio Orvieto',
    role: 'Principal Investigator, ELLIS Institute Tübingen',
    image: 'images/people/AntonioOrvieto/photo.jpg',
    markdown: `
PhD student interested in the dynamics of stochastic optimization algorithms, and I mainly worked on acceleration and adaptiveness. My background is in control theory and signal processing: I obtained a Master's degree in Robotics, Systems and Control from ETH and a Bachelor's degree in Information Engineering from the University of Padua. For more information, please check my [website](http://orvi.altervista.org/).
      `
  },
  {
    name: 'Dario Pavllo',
    role: 'Deep Learning Engineer at QuantCo',
    image: 'images/people/DarioPavllo/photo.jpg',
    externalUrl: `https://ch.linkedin.com/in/dario-pavllo`
  },
  {
    name: 'Luca Biggio',
    role: 'Postdoctoral Research Fellow, EPFL',
    image: 'images/people/LucaBiggio/photo.jpg',
    externalUrl: 'https://www.linkedin.com/in/luca-biggio/?originalSubdomain=it'
  },
  {
    name: 'Seyed-Mohsen Moosavi-Dezfooli',
    role: 'Lecturer, Imperial College London',
    image: 'images/people/SeyedMohsenMoosaviDezfooli/photo.jpg',
    externalUrl: 'https://smoosavi.me/'
  },
  {
    name: 'Jonas Kohler',
    role: 'Researcher/Engineer, Meta',
    image: 'images/people/JonasKohler/photo.jpg',
    externalUrl: 'https://www.linkedin.com/in/jonasmoritzkohler/?originalSubdomain=ch'
  },
  {
    name: 'Giambattista Parascandolo',
    role: 'Research Scientist, OpenAI',
    image: 'images/people/GiambattistaParascandolo/photo.jpg',
    externalUrl: 'https://www.linkedin.com/in/giambattista-parascandolo-216787a4/'
  },
  {
    name: 'Aurelien Lucchi',
    role: 'Faculty, University of Basel',
    image: 'images/people/AurelienLucchi/photo.jpg',
    externalUrl: 'https://omls.dmi.unibas.ch/en/persons/aurelien-lucchi/'
  },
  {
    name: 'Paulina Grnarova',
    role: 'CEO, Co-Founder, DeepJudge',
    image: 'images/people/PaulinaGrnarova/photo.jpg',
    externalUrl: 'https://www.linkedin.com/in/paulinagrnarova'
  },
  {
    name: 'Yannic Kilcher',
    role: 'CTO, Co-Founder, DeepJudge',
    image: 'images/people/YannicKilcher/photo.jpg',
    externalUrl: 'https://ykilcher.com',
  },
  {
    name: 'Kevin Roth',
    role: 'Chief Data Scientist, Co-Founder, DeepJudge',
    image: 'images/people/KevinRoth/photo.jpeg',
    externalUrl: 'https://www.linkedin.com/in/kevinsroth/'
  },
  {
    name: 'Leonard Adolphs',
    role: 'Deep Learning Engineer at QuantCo',
    image: 'images/people/LeonardAdolphs/Passbild-1.png',
    externalUrl: `https://leox1v.com/`
  },
  {
    name: 'Hadi Daneshmand',
    role: 'Postdoctoral Research Fellow, Princeton',
    image: 'images/people/HadiDaneshmand/photo.jpg',
    externalUrl: 'https://ece.princeton.edu/people/hadi-daneshmand'
  },
  {
    name: 'Gary Becigneul',
    role: 'CEO, Co-Founder, Gematria',
    image: 'images/people/GaryBecigneul/photo.jpg',
    externalUrl: 'https://www.linkedin.com/in/gary-b%C3%A9cigneul-phd-9b2bb0110/'
  },
  {
    name: 'Florian Schmidt',
    role: 'Head of Research, Co-Founder, DeepJudge',
    image: 'images/people/FlorianSchmidt/photo.jpg',
    externalUrl: 'https://www.linkedin.com/in/florian-schmidt-phd/'
  },
  {
    name: 'Celestine Mendler-Dünner',
    role: 'Research Group Leader,  Max Planck Institute for Intelligent Systems in Tübingen',
    image: 'images/people/CelestineDuenner/photo.jpg',
    externalUrl: 'http://www.celestine.ai/'
  },
  {
    name: 'Octavian Ganea',
    role: 'Alumnus',
    image: 'images/people/OctavianGanea/photo.jpg',
    externalUrl: 'https://news.mit.edu/2022/remembering-octavian-eugen-ganea-0707'
  },
  {
    name: 'Martin Jaggi',
    role: 'Faculty, EPFL',
    image: 'images/people/MartinJaggi/photo.jpg',
    externalUrl: 'https://www.epfl.ch/labs/mlo/'
  },
  {
    name: 'Martin Kiefel',
    role: 'Senior Applied Scientist, Amazon',
    image: 'images/people/MartinKiefel/photo.jpg',
    externalUrl: 'https://www.linkedin.com/in/martin-kiefel-3762a9b/'
  },
  {
    name: 'Jason Lee',
    role: ' Senior Machine Learning Scientist, Tesla Autopilot',
    image: 'images/people/JasonLee/photo.jpg',
    externalUrl: 'https://jasonleeinf.github.io/'
  }
];


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  cardImage: {
    width: '13em',
    maxHeight: '11em',
    objectFit: 'contain',
    margin: '1em',
  },
  mainImage: {
    width: '13em',
    maxHeight: '11em',
    objectFit: 'contain',
  },
}));

current_people.map(p => p.slug = slugify(p.name, { remove: /[\s]/g }))
alumni.map(p => p.slug = slugify(p.name, { remove: /[\s]/g }))

function PeopleGrid() {
  const classes = useStyles();
  const history = useHistory();

  function goToPerson(person, alumni) {
    if (person.externalUrl) {
      openInNewTab(person.externalUrl);
    } else {
      if (alumni) {
        history.push(`/people/alumni/${person.slug}`);
      } else {
        history.push(`/people/${person.slug}`);
      }

    }
  }

  return (
    <>
      <DalabGrid data={current_people}>
        {(person) =>
          <Card>
            <CardActionArea onClick={() => goToPerson(person, false)}>
              <Box height='13em' display='flex' alignItems='flex-start' justifyContent='space-between'>
                <CardHeader title={person.name} subheader={person.role} />
                <CardMedia component='img' className={classes.cardImage} image={process.env.PUBLIC_URL + '/' + person.image} />
              </Box>
            </CardActionArea>
          </Card>
        }
      </DalabGrid>
      <hr />
      <DalabGrid data={alumni}>
        {(person) =>
          <Card>
            <CardActionArea onClick={() => goToPerson(person, true)}>
              <Box height='13em' display='flex' alignItems='flex-start' justifyContent='space-between'>
                <CardHeader title={person.name} subheader={person.role} />
                <CardMedia component='img' className={classes.cardImage} image={process.env.PUBLIC_URL + '/' + person.image} />
              </Box>
            </CardActionArea>
          </Card>
        }
      </DalabGrid>
    </>
  )
}

function Person({ person }) {
  const classes = useStyles();
  if (!person || person.externalUrl) return <Redirect to='/people' />
  const infoParts = [
    { title: 'E-Mail', prop: 'email', reverse: true },
    { title: 'Room', prop: 'room' },
    { title: 'Address', prop: 'address' },
    { title: 'Phone', prop: 'phone' },
  ]
  return (
    <>
      <Paper className={classes.paper}>
        <Box px={3} py={2}>
          <Typography variant='h4' gutterBottom>
            {person.name}
          </Typography>
          <Box display='flex' alignItems='flex-start' flexWrap='wrap' mb={4}>
            <img className={classes.mainImage} src={process.env.PUBLIC_URL + '/' + person.image} alt='portrait' />
            <Box mt='1em'>
              <Typography variant='subtitle1' gutterBottom>
                {person.role}
              </Typography>
              {infoParts.map(({ title, prop, reverse }, idx) => person[prop] &&
                <Box key={idx}>
                  <Typography style={{ fontWeight: 'bold' }} display='inline'>{title}:&nbsp;</Typography>
                  <Typography display='inline' className={reverse && 'text-reverse'}>{person[prop]}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          {person.markdown && <Markdown>
            {person.markdown}
          </Markdown>
          }
        </Box>
      </Paper>
    </>
  );
}

export default function () {
  const match = useRouteMatch();
  return (
    <>
      <Route exact path={match.path} component={PeopleGrid} />
      <Route exact path={`${match.path}/alumni/:slug`} render={({ match }) =>
        <Person person={alumni.find(p => p.slug === match.params.slug)} />
      } />
      <Route exact path={`${match.path}/:slug`} render={({ match }) =>
        <Person person={current_people.find(p => p.slug === match.params.slug)} />
      } />
    </>
  );
}
